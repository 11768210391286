<template>
  <div class="bg-white">
    <!-- Hero section -->
    <div class="relative bg-gray-900">
      <!-- Decorative image and overlay -->
      <div
        aria-hidden="true"
        class="absolute inset-0 overflow-hidden bg-white"
      />

      <div aria-hidden="true" class="absolute inset-0 bg-black opacity-90" />

      <div
        class="relative max-w-3xl mx-auto py-40 px-6 flex flex-col items-center text-center sm:py-30 sm:py-40 lg:px-0"
      >
        <img class="h-20" src="@/assets/5.png" alt="" />
        <h3
          class="mt-4 text-5xl text-white font-bold"
          data-aos="fade-in"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          404 Page not found
        </h3>

        <div class="mt-8">
          <router-link
            to="/"
            class="inline-block border-white border border-transparent py-3 px-8 text-base font-medium text-white hover:bg-gray-100 hover:text-black"
            >Back Home</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
